import Vue from 'vue'
import App from './App.vue'

import VueCoreVideoPlayer from 'vue-core-video-player'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router'

Vue.use(ElementUI);

Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  //判断token是否存在
  const token = localStorage.getItem("Token")
  //token不存在，说明用户未登录，需要跳转至登陆页面
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
    //token存在，说明用户已登录，防止重复登陆，禁止访问登陆页面
  } else if (token && to.name === 'login') {
    next({ name: 'index' })
    //其它情况，该去哪去哪
  } else {
    // 设置网页标题
    document.title = to.meta.title || '默认标题'
    next()
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

