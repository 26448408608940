<template>
    <el-menu router :default-active="this.$router.path" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="index"><span class="firstTitle">我的世界</span></el-menu-item>
        <el-submenu :index="item.component" v-for="item in menuList" :key="item.uid" :ref="item.uid">
            <template slot="title"><span class="headerTitle">{{ item.name }}</span></template>
            <el-menu-item :index="children.component" v-for="children in item.menuList" :key="children.uid">{{ children.name }}</el-menu-item>
        </el-submenu>
        <el-avatar :size="40" :src="userInfo.avatar" style="float:right;margin-top: 0.5%;">
            <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
        </el-avatar>
        <el-submenu index="2" style="float:right">
            <template slot="title" index="2-0">{{ userInfo.nickName }}</template>
            <el-menu-item index="2-1" @click="goMine">个人详情</el-menu-item>
            <el-menu-item index="" @click="open">退出登录</el-menu-item>
        </el-submenu>
    </el-menu>
</template>
<script>
import { GetMenuList } from '@/api/manager'
export default {
    data() {
        return {
            userInfo: {},
            menuList: {}
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const res = await GetMenuList()
            this.menuList = res.data.data
            this.userInfo = JSON.parse(localStorage.getItem('UserInfo')) 
        },
        handleSelect(key, keyPath) {
        },
        open() {
            this.$confirm('是否确认退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 执行退出登录操作
                this.$message({
                    type: 'success',
                    message: '退出登录成功!'
                });
                localStorage.removeItem("Token")
                localStorage.removeItem("UserInfo")
                this.$router.push('/login');
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消退出'
                });
            });
        },
        goMine() {
            this.$router.push('mine')
        }

    }
}
</script>
<style>
.firstTitle {
    font-size: 200%;
}

.headerTitle {
    font-size: 150%;
}

.el-menu--popup {
    min-width: 95px !important;
    text-align: center;
}
</style>