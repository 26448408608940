<template>
    <div>
        <div class="block">
            <el-date-picker v-model="time" type="monthrange" align="right" unlink-panels range-separator="至"
                start-placeholder="开始月份" end-placeholder="结束月份" :picker-options="pickerOptions">
            </el-date-picker>
            <el-button icon="el-icon-search" circle style="margin-left: 1%;" @click="selectTime()"></el-button>
        </div>
        <div id="chart-container" class="chart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import { GetTransactionYear } from '@/api/Transaction'

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    BarChart,
    CanvasRenderer,
    LineChart
]);
export default {
    data() {
        return {
            zcList: {},
            srList: {},
            yearMonthList: {},
            pickerOptions: {
                shortcuts: [{
                    text: '本月',
                    onClick(picker) {
                        picker.$emit('pick', [new Date(), new Date()]);
                    }
                }, {
                    text: '今年至今',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date(new Date().getFullYear(), 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近六个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() - 6);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            time: [],
            myChart: {}
        }
    },
    mounted() {
        // 获取到容器元素
        const chartContainer = document.getElementById('chart-container');
        // 基于容器元素初始化图表
        this.myChart = echarts.init(chartContainer);
        this.defaultDate()
        setTimeout(() => {
            this.set()
        }, 1000)
        this.myChart.on('click', function (params) {
            window.open('/#/TransactionMonth?dateTime='+params.name);
        });
    },
    methods: {
        defaultDate() {
            const firstTime = new Date()
            const lastTime = new Date()
            firstTime.setFullYear(firstTime.getFullYear(), 0)
            lastTime.setFullYear(lastTime.getFullYear(), 11);
            this.time.push(firstTime)
            this.time.push(lastTime)
            this.getData()
        },
        async getData() {
            const firstYear = this.time[0].getFullYear()
            const firstMonth = this.time[0].getMonth() + 1
            const lastYear = this.time[1].getFullYear()
            const lastMonth = this.time[1].getMonth() + 1
            const params = { startTime: firstYear + '-' + firstMonth, endTime: lastYear + '-' + lastMonth }
            const { data: res } = await GetTransactionYear(params)
            this.srList = res.data.moneyList1
            this.zcList = res.data.moneyList2
            this.yearMonthList = res.data.yearMonthList
        },
        selectTime() {
            this.getData()
            setTimeout(() => {
                this.set()
            }, 1500)
        },
        set() {
            // 使用配置项显示图表
            this.myChart.setOption({
                title: {
                    text: '支出 vs 收入'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['支出', '收入']
                },
                toolbox: {
                    show: true,
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        // prettier-ignore
                        data: this.yearMonthList
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '支出',
                        type: 'bar',
                        data: this.zcList,
                        makeName: this.zcList,
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        }
                    },
                    {
                        name: '收入',
                        type: 'bar',
                        data: this.srList,
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        }
                    }
                ]
            });
        }
    }
};
</script>
<style scoped>
.chart {
    margin-top: 1%;
    height: 780px;
    width: 100%;
}
</style>@/api/Transaction