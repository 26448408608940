<template>
    <div class="loginbody">
        <div class="logindata">
            <div class="logintext">
                <h2>Welcome</h2>
            </div>
            <div class="formdata">
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="username">
                        <el-input v-model="form.mail" placeholder="请输入邮箱">
                            <el-button style="padding-right:10px" slot="suffix" type="text" :disabled="countdown > 0"
                                @click="getCaptcha">
                                {{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="form.code" placeholder="请输入验证码">
                            <el-button style="padding-right:10px" slot="suffix" type="text"
                                @click.native.prevent="login('form')" @keyup.enter.native="enterLogin">登录</el-button>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { Login, GetCode } from "@/api/User";

export default {
    name: "login",
    data() {
        return {
            form: {
                mail: "",
                code: ""
            },
            checked: false,
            rules: {
                mail: [
                    { required: true, message: "请输入用户名", trigger: "blur" },
                    { max: 10, message: "不能大于10个字符", trigger: "blur" },
                ],
                code: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { max: 10, message: "不能大于10个字符", trigger: "blur" },
                ],
            },
            countdown: 0
        };
    },
    mounted() {
        if (localStorage.getItem("news")) {
            this.form = JSON.parse(localStorage.getItem("news"))
            this.checked = true
        }
    },
    created() {
        this.enterLogin()
    },
    methods: {
        login() {
            Login(this.form).then((res) => {
                if (res.data && res.data.code === 200) {
                    localStorage.setItem('Token', res.data.data.token)
                    localStorage.setItem('UserInfo', JSON.stringify(res.data.data))
                    this.$message({
                        message: "登录成功啦",
                        type: "success",
                        showClose: true,
                    });
                    this.$router.replace("/index");
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                        showClose: true,
                    });
                }
            }).catch((err) => {
                this.$message({
                    message: "邮箱或验证码错误",
                    type: "error",
                    showClose: true,
                });
            });
        },
        verify(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.login()
                } else {
                    return false;
                }
            });
        },
        enterLogin() {
            document.onkeydown = e => {
                if (this.$route.path === '/login' && e.keyCode === 13) {
                    if (!this.form) {
                        this.$message({
                            message: "邮箱或验证码不存在！",
                            type: "error",
                            showClose: true,
                        });
                        return false;
                    }
                    if (!this.form.mail) {
                        this.$message({
                            message: "邮箱不存在！",
                            type: "error",
                            showClose: true,
                        });
                        return false;
                    }
                    if (!this.form.code) {
                        this.$message({
                            message: "验证码不存在！",
                            type: "error",
                            showClose: true,
                        });
                        return false;
                    }
                    this.login()
                }
            }
        },
        getCaptcha() {
            if (!this.form.mail) {
                this.$message({
                    message: "邮箱不存在！",
                    type: "error",
                    showClose: true,
                });
                return false;
            }
            // 发起获取验证码的请求
            GetCode({ mail: this.form.mail }).then((res) => {
                if (res.data && res.data.code === 200) {
                    this.$message({
                        message: res.data.msg,
                        type: "success",
                        showClose: true,
                    });
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                        showClose: true,
                    });
                }
            }).catch((err) => {
                this.$message({
                    message: "邮箱错误",
                    type: "error",
                    showClose: true,
                });
            });

            // 启动60秒倒计时
            this.countdown = 60;
            this.intervalId = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown -= 1;
                } else {
                    clearInterval(this.intervalId);
                }
            }, 1000);

        }
    },
};
</script>

<style scoped>
.loginbody {
    width: 100%;
    height: 100%;
    min-width: 1000px;
    background-image: url("https://file.starliang.xyz:8888/Image/login.jpg");
    background-size: 100% 100%;
    background-position: center center;
    overflow: auto;
    background-repeat: no-repeat;
    position: fixed;
    line-height: 100%;
    padding-top: 150px;
}

.logintext {
    margin-bottom: 20px;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

.logindata {
    width: 18%;
    margin: auto;
}

.tool {
    display: flex;
    justify-content: space-between;
    color: #606266;
}

.butt {
    /* margin-top: 10px; */
    text-align: center;
}

.shou {
    cursor: pointer;
    color: #606266;
}

/*ui*/
/* /deep/ .el-form-item__label {
    font-weight: bolder;
    font-size: 15px;
    text-align: left;
  }
  
  /deep/ .el-button {
    width: 100%;
    margin-bottom: 10px;
  
  } */
</style>