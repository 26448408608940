<template>
    <div class="dashboard-container">
        <!--    <div class="dashboard-text">name: {{ name }}</div>-->
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    </div>
</template>
  
<script>

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import dayjs from 'dayjs'

export default {
    components: {
        FullCalendar,
    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin],
                handleWindowResize: true,
                initialView: 'dayGridMonth', // 默认为那个视图（月：dayGridMonth，周：timeGridWeek，日：timeGridDay）
                firstDay: 1, // 设置一周中显示的第一天是哪天，周日是0，周一是1，类推
                locale: 'zh-cn', // 切换语言，当前为中文
                initialDate: dayjs().format('YYYY-MM-DD'), // 自定义设置背景颜色时一定要初始化日期时间
                aspectRatio: 2, // 设置日历单元格宽度与高度的比例。
                eventLimit: true,
                headerToolbar: { // 日历头部按钮位置
                    left: '',
                    center: 'prevYear,prev title next,nextYear',
                    right: ''
                },
                customButtons: {
                    prevYear: {
                        text: 'prevYear',
                        click: () => {
                            this.headerClick('prevYear')
                        }
                    },
                    prev: {
                        text: 'prev',
                        click: () => {
                            this.headerClick('prev')
                        }
                    },
                    next: {
                        text: 'next',
                        click: () => {
                            this.headerClick('next')
                        }
                    },
                    nextYear: {
                        text: 'nextYear',
                        click: () => {
                            this.headerClick('nextYear')
                        }
                    }
                },
                eventClick: this.handleEventClick,
                events: [
                    // {
                    //   title: 'DCA培训',
                    //   start: '2022-09-06 10:00:00',
                    //   end: '2022-09-06 23:59:59'
                    // }
                ]
            },
            calendarApi: null,
            timeParams: ''
        }
    },
    mounted() {
        this.calendarApi = this.$refs.fullCalendar.getApi()
        // this.findByTime()
    },
    methods: {
        getTime() {
            const time = this.calendarApi.currentDataManager.state.dateProfile.renderRange
            const start = dayjs(time.start).format('YYYY-MM-DD HH:mm:ss')
            const end = dayjs(time.end).format('YYYY-MM-DD HH:mm:ss')
            return { start, end }
        },
        async findByTime() {
            const { start, end } = this.getTime()
            const res = await findByTime({ startTime: start, endTime: end })
            const data = res.data
            if (data && data.code === 200 && data.data.examRoomList && data.data.trainList) {
                const examRoomList = data.data.examRoomList.map(item => {
                    return {
                        title: item.roomName + '-已报名:' + item.amount + '人，可报:' + item.num + '人',
                        start: item.examTime,
                        end: item.examTime,
                        color: '#E6A23C'
                    }
                })
                const trainList = data.data.trainList.map(item => {
                    return {
                        title: item.trainName + '-已报名:' + item.amount + '人，可报:' + item.num + '人',
                        start: item.startTime,
                        end: item.endTime,
                        color: '#67C23A'
                    }
                })
                this.calendarOptions.events = [...examRoomList, ...trainList]
            }
        },
        headerClick(str) {
            switch (str) {
                case 'prevYear':
                    this.calendarApi.prevYear()
                    break
                case 'prev':
                    this.calendarApi.prev()
                    break
                case 'next':
                    this.calendarApi.next()
                    break
                case 'nextYear':
                    this.calendarApi.nextYear()
                    break
            }
            // this.findByTime()
        },
        handleEventClick(calEvent) {
            console.log(calEvent, '日程事件')
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
    width: 100%;
    /*height: 60%;*/
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
    margin: 0 auto;
}

::v-deep .fc-toolbar-chunk {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .fc-toolbar-title {
        margin: 0 30px;
    }

    .fc-button-primary {
        color: #333;
        background-color: #f4f4f5;
        border-color: #f4f4f5;
        margin: 0 10px;
        padding: 0.2em 0.4em;
    }

    .fc-button-primary:active,
    .fc-button-primary:focus {
        background-color: #f4f4f5;
        border-color: #f4f4f5;
        box-shadow: 0 0 0 transparent;
    }
}

::v-deep .fc-daygrid-day-frame {
    .fc-event-title {
        white-space: pre-wrap;
    }

    .fc-event-time {
        display: none;
    }
}

::v-deep .fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(199, 237, 204);
}

::v-deep .fc-scrollgrid {
    background-color: #f4f4f5;
}
</style>