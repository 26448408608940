import http from '@/utils/axios'

export const TransactionDate = (params) => {
    return http.get('/Transaction/TransactionDate', { params: params })
}

export const TransactionList = (params) => {
    return http.get('/Transaction/TransactionList', { params: params })
}

export const GetTransaction = (params) => {
    return http.get('/Transaction/GetTransaction', { params: params })
}

export const AddTransaction = (data) => {
    return http.post('/Transaction/AddTransaction', data)
}

export const DelTransaction = (data) => {
    return http({
        url: '/Transaction/DelTransaction', 
        method: 'delete',
        data,
        ContentType: 'multipart/form-data'
    })
}

export const UdpTransaction = (data) => {
    return http.post('/Transaction/UdpTransaction', data)
}

export const GetTransactionYear = (params) => {
    return http.get('/Transaction/GetTransactionYear', { params: params })
}

export const GetTransactionMonth = (params) => {
    return http.get('/Transaction/GetTransactionMonth', { params: params })
}

export const GetMonthType = (params) => {
    return http.get('/Transaction/GetMonthType', { params: params })
}


export const GetMoneyTypeList = (params) => {
    return http.get('/TransactionConfig/GetMoneyTypeList', { params: params })
}

export const GetMyBankList = (params) => {
    return http.get('/TransactionConfig/GetMyBankList', { params: params })
}



