<template>
    <el-container>
        <el-header>
            <Header />
        </el-header>
        <el-container>
            <el-main><router-view></router-view></el-main>
        </el-container>
    </el-container>
</template>
<script>
import Header from '@/components/Header.vue'
export default {
    data() {
        return {}
    },
    components: {
        Header
    }
}
</script>