<template>
    <div>
        <div>
            <div id="zcChart-month" class="left-chart"></div>
            <div id="zcChart-day" class="right-chart"></div>
        </div>
        <div>
            <div id="srChart-month" class="left-chart"></div>
            <div id="srChart-day" class="right-chart"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { GetTransactionMonth, GetMonthType } from '@/api/Transaction'
echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);
export default {
    data() {
        return {
            zcList: [],
            zcKeyList: [],
            srList: [],
            srKeyList: [],
            zcdayList: [],
            srdayList: []
        }
    },
    mounted() {
        // 获取到容器元素
        const zcChartMonth = document.getElementById('zcChart-month');
        const srChartMonth = document.getElementById('srChart-month');
        const zcChartday = document.getElementById('zcChart-day');
        const srChartday = document.getElementById('srChart-day');
        // 基于容器元素初始化图表
        this.myChart1 = echarts.init(zcChartMonth);
        this.myChart2 = echarts.init(srChartMonth);
        this.myChart3 = echarts.init(zcChartday);
        this.myChart4 = echarts.init(srChartday);
        this.getData1()
        setTimeout(() => {
            this.set1()
        }, 1000)
        const _this = this
        this.myChart1.on('click', function (params) {
            _this.zcNumList = []
            _this.zcdayList = []
            _this.getData2(params.data.uid, 1)
            setTimeout(() => {
                _this.set2()
            }, 1000)
        })
        this.myChart2.on('click', function (params) {
            _this.srNumList = []
            _this.srdayList = []
            _this.getData2(params.data.uid, 0)
            setTimeout(() => {
                _this.set3()
            }, 1000)
        })
        this.myChart3.on('click', function (params) {
            console.log(params)
        })
    },
    methods: {
        async getData1() {
            this.dateTime = this.$route.query.dateTime
            const params = { dateTime: this.dateTime }
            const { data: res } = await GetTransactionMonth(params)
            const zcList = res.data.zcList
            const srList = res.data.srList
            for (const key in zcList) {
                const zcKey = key.split('-')[0]
                this.zcKeyList.push(zcKey)
                const zcUid = parseInt(key.split('-')[1])
                const value = zcList[key]
                this.zcList.push({ name: zcKey, value: value, uid: zcUid })
            }
            for (const key in srList) {
                const srKey = key.split('-')[0]
                this.srKeyList.push(srKey)
                const srUid = parseInt(key.split('-')[1])
                const value = srList[key]
                this.srList.push({ name: srKey, value: value, uid: srUid })
            }
        },
        async getData2(uid, x) {
            this.dateTime = this.$route.query.dateTime
            const params = { dateTime: this.dateTime, uid: uid }
            const { data: res } = await GetMonthType(params)
            for (const key in res.data) {
                const value = res.data[key]
                if (x == 1) {
                    this.zcdayList.push({ bankName: value.myBank.bankName, name: value.times, value: value.num })
                } else {
                    this.srdayList.push({ bankName: value.myBank.bankName, name: value.times, value: value.num })
                }
            }
        },
        set1() {
            this.myChart1.setOption({
                title: {
                    text: this.dateTime + '-支出',
                    subtext: '单位（元/￥）',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                    data: this.zcKeyList
                },
                series: [
                    {
                        name: '支出',
                        type: 'pie',
                        radius: '55%',
                        center: ['40%', '50%'],
                        data: this.zcList,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
            this.myChart2.setOption({
                title: {
                    text: this.dateTime + '-收入',
                    subtext: '单位（元/￥）',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                    data: this.srKeyList
                },
                series: [
                    {
                        name: '收入',
                        type: 'pie',
                        radius: '55%',
                        center: ['40%', '50%'],
                        data: this.srList,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        },
        set2() {
            this.myChart3.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        return params.data.name + '<br/>' + params.data.bankName + " : " + params.data.value + "(单位/元)"
                    }
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.zcdayList
                    }
                ]
            })
        },
        set3() {
            this.myChart4.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        return params.data.name + '<br/>' + params.data.bankName + " : " + params.data.value + "(单位/元)"
                    }
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.srdayList
                    }
                ]
            })
        }
    }
}
</script>
<style scoped>
.left-chart {
    margin-top: 1%;
    height: 400px;
    width: 45%;
    float: left;
}

.right-chart {
    margin-top: 1%;
    margin-left: 3%;
    height: 400px;
    width: 50%;
    float: left;
}
</style>@/api/Transaction