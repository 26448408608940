<template>
    <div>
        <div>
            <el-col :span="2">
                <el-menu default-active="2" class="el-menu-vertical-demo">
                    <el-menu-item index="1">
                        <i class="el-icon-menu"></i>
                        <span slot="title">导航一</span>
                    </el-menu-item>
                    <el-menu-item index="2">
                        <i class="el-icon-menu"></i>
                        <span slot="title">导航二</span>
                    </el-menu-item>
                    <el-menu-item index="3">
                        <i class="el-icon-document"></i>
                        <span slot="title">导航三</span>
                    </el-menu-item>
                    <el-menu-item index="4">
                        <i class="el-icon-setting"></i>
                        <span slot="title">导航四</span>
                    </el-menu-item>
                </el-menu>
            </el-col>
        </div>
        <div>
            <div class="block" style="margin-left: 10%;">
                <el-date-picker v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
                    @change="timeChange">
                </el-date-picker>
                <el-button-group style="float: right;">
                    <el-button type="success" icon="el-icon-upload" @click="dialogVisible = true"></el-button>
                </el-button-group>
            </div>
            <div style="margin-left: 10%;overflow:auto;height: 800px;" v-infinite-scroll="load">
                <div v-for="k in keyList" :key="k.key">
                    <el-divider content-position="left">{{ k.key }}</el-divider>
                    <template>
                        <el-image v-for="item in k.list" :key="item.uuid" :src="item.url" :title="item.fileName"
                            :preview-src-list="item.srcList" slot="error">
                            <i class="el-icon-picture-outline"></i>
                        </el-image>
                    </template>
                </div>
                <p v-if="!hasNextPage" style="text-align: center;">没有更多了</p>
            </div>
        </div>
        <div>
            <el-dialog title="上传" :visible.sync="dialogVisible" width="400px" :before-close="handleClose">
                <el-upload class="upload-demo" drag :on-preview="handlePreview" :on-remove="handleFileRemove"
                    :auto-upload="false" :before-upload="beforeFileUpload" :on-change="handleChange"
                    action="/File/Image" :file-list="fileList" list-type="picture" multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过20M</div>
                </el-upload>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="close">取 消</el-button>
                    <el-button type="primary" @click="handleFileUpload">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { UploadImage, GetDateList, GetImageList } from '@/api/file'
import dayjs from 'dayjs'
export default {
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            time: [],
            dialogVisible: false,
            fileList: [],
            startTime: '',
            endTime: '',
            keyList: [],
            formData: new FormData(),
            pageSize: 10,
            pageNum: 1,
            hasNextPage: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const params1 = { startTime: this.startTime, endTime: this.endTime, pageSize: this.pageSize, pageNum: this.pageNum }
            const res1 = await GetDateList(params1)
            if (res1 && res1.data.data && res1.data.code === 200) {
                const keyList = res1.data.data.list
                const res2 = await GetImageList(keyList)
                if (res2.data && res2.data.code === 200 && res2.data.data) {
                    for (let key in res2.data.data) {
                        this.keyList.push({ key: key, list: res2.data.data[key] })
                    }
                    this.hasNextPage = res1.data.data.hasNextPage
                }
                console.log(this.keyList)
            }
        },
        async load() {
            if (this.hasNextPage) {
                this.pageNum = this.pageNum + 1
                this.getData()
            }
        },
        timeChange() {
            this.startTime = dayjs(this.time[0]).format('YYYY-MM-DD')
            this.endTime = dayjs(this.time[1]).format('YYYY-MM-DD')
            this.keyList = []
            this.hasNextPage = false
            this.getData()
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                    this.fileList = []
                })
                .catch(_ => { });
        },
        handlePreview(file) {
            console.log(file);
        },
        handleChange(file, fileList) {
            if (fileList.length != 0) {
                const formData = new FormData()
                fileList.forEach(item => {
                    if (this.beforeFileUpload(item)) {
                        formData.append('files', item.raw)
                    }
                })
                this.formData = formData
            }
        },
        //文件上传条件 
        beforeFileUpload(file) {
            console.log(file, 'file的信息')
            // 限制大小在20MB之内
            let isLt20M = file.size / 1024 / 1024 < 20
            if (!isLt20M) {
                this.$message.error("上传文件大小不能超过 20MB!")
                return false;
            }
            // 限制类型只能为图片
            let fileTypes = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG']
            const suffix = file.name.match(/[^.]+$/)[0] //获取文件后缀名
            // let isResPackage = file.raw.type.includes('zip') // 是否为zip格式
            if (fileTypes.indexOf(suffix) === -1) {
                this.$message.error("请上传正确格式的文件!")
                return false;
            }
            return true;
        },
        // 自定义文件上传
        handleFileUpload() {
            // console.log(this.formData)
            UploadImage(this.formData).then((res) => {
                console.log(res)
                if (res.data && res.data.code === 200) {
                    this.$message({
                        message: res.data.msg,
                        type: "success",
                        showClose: true,
                    });
                    this.$router.go(0)
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                        showClose: true,
                    });
                }
            }).catch((err) => {
                this.$message({
                    message: "文件上传失败",
                    type: "error",
                    showClose: true,
                });
            });
        },
        close() {
            this.fileList = []
            this.dialogVisible = false
        },
        //移除上传文件
        handleFileRemove(file, fileList) {
            this.fileList = fileList
        }
    }
}
</script>
<style>
.el-image {
    position: relative;
    /* width: 10%; */
    height: 150px;
    margin: 10px;
}
</style>