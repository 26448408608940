import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/view/login.vue'
import index from '@/view/index.vue'
import main from '@/view/main.vue'
import transactionYear from '@/view/Transaction/year.vue'
import transactionMonth from '@/view/Transaction/month.vue'
import transactionIndex from '@/view/Transaction/index.vue'
import transactionList from '@/view/Transaction/list.vue'
import fileIndex from '@/view/Files/index.vue'

//备份原型对象已有的push|replace方法
let push = VueRouter.prototype.push;
let replace = VueRouter.prototype.replace;
//重写VueRouter.prototype原型对象的push|replace方法
VueRouter.prototype.push = function (location) {
    //调用push方法且篡改push方法内部this指向->vueRouter类的实例
    push.call(this, location, () => { }, () => { });
}
VueRouter.prototype.replace = function (location) {
    //调用push方法且篡改push方法内部this指向->vueRouter类的实例
    replace.apply(this, [location, () => { }, () => { }]);
}
const routes = [
    {
        path: '/',
        component: main,
        redirect: '/index',
        children: [
            {
                path: 'index',
                component: index,
                name: 'index',
                meta: {
                    title: '首页'
                }
            },
            {
                path: 'transactionYear',
                component: transactionYear,
                name: 'transactionYear',
                meta: {
                    title: '交易年报'
                }
            },
            {
                path: 'transactionMonth',
                component: transactionMonth,
                name: 'transactionMonth',
                meta: {
                    title: '交易月报'
                }
            },
            {
                path: 'transactionIndex',
                component: transactionIndex,
                name: 'transactionIndex',
                meta: {
                    title: '交易月历'
                }
            },
            {
                path: 'transactionList',
                component: transactionList,
                name: 'transactionList',
                meta: {
                    title: '交易列表'
                }
            },
            {
                path: 'fileIndex',
                component: fileIndex,
                name: 'fileIndex',
                meta: {
                    title: '文件列表'
                }
            }
        ]
    },
    {
        path: '/login',
        component: login,
        name: 'login',
        meta: {
            title: '登陆'
        }
    }
]

const router = new VueRouter({
    routes,
    mode:'hash',
})

export default router

Vue.use(VueRouter)