<template>
    <div>
        <div class="dashboard-container">
            <!--    <div class="dashboard-text">name: {{ name }}</div>-->
            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
        </div>
        <el-dialog :title="chooseDate" :visible.sync="dialogVisible" width="550px" :before-close="handleClose">
            <el-form :model="money" :ref="money" label-width="100px" class="demo-ruleForm">
                <el-form-item label="交易时间" required>
                    <el-col :span="17">
                        <el-form-item>
                            <el-time-picker placeholder="选择时间" v-model="money.time"  style="width: 100%;"
                                value-format="hh:mm:ss"></el-time-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="交易类型" required>
                    <el-col :span="17">
                        <el-form-item>
                            <el-cascader v-model="money.types" :options="types" :props="{ expandTrigger: 'hover' }" style="width: 290px">
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="交易金额" required>
                    <el-form-item>
                        <el-input-number v-model="money.num" :precision="2" :step="0.01" style="width: 290px"
                            controls-position="right"></el-input-number>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="交易方式" required>
                    <el-col :span="17">
                        <el-form-item>
                            <el-select v-model="money.bank" placeholder="选择方式" style="width: 290px">
                                <el-option v-for="item in banks" :key="item.uuid" :label="item.bankName"
                                    :value="item.uuid">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="交易对象" required>
                    <el-col :span="17">
                        <el-form-item>
                            <el-input placeholder="请输入对象名" v-model="money.oName" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="交易单号" required>
                    <el-col :span="17">
                        <el-form-item>
                            <el-input placeholder="请输入订单号" v-model="money.oid" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="交易备注" required>
                    <el-col :span="17">
                        <el-form-item>
                            <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="money.remark">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm()" style="float: left;">取 消</el-button>
                <el-button v-if="addMoney" type="primary" @click="addTransaction()">添 加</el-button>
                <el-button v-if="!addMoney" type="warning" @click="udpTransaction()">修 改</el-button>
                <el-button v-if="!addMoney" type="danger" @click="delTransaction()">删 除</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';

import dayjs from 'dayjs'
import { TransactionDate, GetTransaction, AddTransaction, DelTransaction, UdpTransaction, GetMoneyTypeList, GetMyBankList } from "@/api/Transaction"

export default {
    components: {
        FullCalendar,
    },
    data() {
        return {
            calendarOptions: {
                plugins: [interactionPlugin, dayGridPlugin],
                handleWindowResize: true,
                initialView: 'dayGridMonth', // 默认为那个视图（月：dayGridMonth，周：timeGridWeek，日：timeGridDay）
                firstDay: 1, // 设置一周中显示的第一天是哪天，周日是0，周一是1，类推
                locale: 'zh-cn', // 切换语言，当前为中文
                initialDate: dayjs().format('YYYY-MM-DD'), // 自定义设置背景颜色时一定要初始化日期时间
                aspectRatio: 2, // 设置日历单元格宽度与高度的比例。
                eventLimit: true,
                headerToolbar: { // 日历头部按钮位置
                    left: '',
                    center: 'prevYear,prev title next,nextYear',
                    right: ''
                },
                customButtons: {
                    prevYear: {
                        text: 'prevYear',
                        click: () => {
                            this.headerClick('prevYear')
                        }
                    },
                    prev: {
                        text: 'prev',
                        click: () => {
                            this.headerClick('prev')
                        }
                    },
                    next: {
                        text: 'next',
                        click: () => {
                            this.headerClick('next')
                        }
                    },
                    nextYear: {
                        text: 'nextYear',
                        click: () => {
                            this.headerClick('nextYear')
                        }
                    }
                },
                eventClick: this.handleEventClick,
                selectable: true, // 是否可以选中日历格
                select: this.selectDate, //选中日历格事件
                events: []
            },
            calendarApi: null,
            dialogVisible: false,
            money: {
                time: '',
                times: '',
                bank: '',
                types: '',
                moneyType: '',
                num: 0.00,
                oName: '',
                oid: '',
                remark: ''
            },
            chooseDate: '',
            types: [],
            banks: [],
            addMoney: true
        }
    },
    mounted() {
        this.calendarApi = this.$refs.fullCalendar.getApi()
        this.findByTime()
        this.getMoneyTypeList()
        this.getMyBankList()
    },
    methods: {
        async getMoneyTypeList() {
            const res = await GetMoneyTypeList()
            if (res.data && res.data.code === 200 && res.data.data) {
                res.data.data.map((item) => {
                    item.moneyTypeList.map((i) => {
                        i.value = i.uid
                        i.label = i.typeName
                    })
                    item.value = item.uid
                    item.label = item.typeName
                    item.children = item.moneyTypeList
                })
                this.types = res.data.data
            }
        },
        async getMyBankList() {
            const userInfo = JSON.parse(localStorage.getItem('UserInfo'))
            const res = await GetMyBankList({ myUuid: userInfo.uuid })
            if (res.data && res.data.code === 200 && res.data.data) {
                this.banks = res.data.data
            }
        },
        getTime() {
            const time = this.calendarApi.currentDataManager.state.dateProfile.renderRange
            const start = dayjs(time.start).format('YYYY-MM-DD HH:mm:ss')
            const end = dayjs(time.end).format('YYYY-MM-DD HH:mm:ss')
            return { start, end }
        },
        async findByTime() {
            const { start, end } = this.getTime()
            const res = await TransactionDate({ startTime: start, endTime: end })
            const data = res.data
            if (data && data.code === 200 && data.data.moneyList1 && data.data.moneyList2) {
                const moneyList1 = data.data.moneyList1.map(item => {
                    return {
                        title: item.times + '\n' + item.myBank.bankName + ' : ' + item.num + '元',
                        start: item.times,
                        end: item.times,
                        color: '#E6A23C',
                        id: item.uuid
                    }
                })
                const moneyList2 = data.data.moneyList2.map(item => {
                    return {
                        title: item.times + '\n' + item.myBank.bankName + ' : ' + item.num + '元',
                        start: item.times,
                        end: item.times,
                        color: '#67C23A',
                        id: item.uuid
                    }
                })
                this.calendarOptions.events = [...moneyList1, ...moneyList2]
            }
        },
        headerClick(str) {
            switch (str) {
                case 'prevYear':
                    this.calendarApi.prevYear()
                    break
                case 'prev':
                    this.calendarApi.prev()
                    break
                case 'next':
                    this.calendarApi.next()
                    break
                case 'nextYear':
                    this.calendarApi.nextYear()
                    break
            }
            this.findByTime()
        },
        async handleEventClick(calEvent) {
            const res = await GetTransaction({ uuid: calEvent.event.id })
            const money = res.data.data
            this.money.uuid = money.uuid
            this.money.bank = money.bank
            this.money.types = money.moneyType
            this.money.num = money.num
            this.money.remark = money.remark
            this.money.moneyType = money.moneyType
            this.money.oName = money.oName
            this.money.oid = money.oid
            this.money.time = dayjs(money.times).format('HH:mm:ss')
            this.dialogVisible = true
            this.chooseDate = dayjs(money.times).format('YYYY-MM-DD')
            this.addMoney = false
        },
        selectDate(arg) {
            this.addMoney = true
            this.dialogVisible = true
            this.chooseDate = arg.startStr
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.resetForm()
                    done();
                })
                .catch(_ => { });
        },
        verifyMoney() {
            if (!this.money.time) {
                this.$message.error('交易时间为必选项！');
                return false
            }
            if (!this.chooseDate) {
                this.$message.error('日期异常请重新选择！');
                this.resetForm()
                return false
            }
            this.money.times = this.chooseDate + " " + this.money.time
            if (!this.money.types) {
                this.$message.error('交易类型为必选项！');
                return false
            }
            if (this.money.types[1]) {
                this.money.moneyType = this.money.types[1]
            }
            if (this.money.num === 0) {
                this.$message.error('交易金额不能为0元！');
                return false
            }
            if (!this.money.bank) {
                this.$message.error('交易方式为必选项！');
                return false
            }
            if (!this.money.oName) {
                this.$message.error('交易对象为必填项！');
                return false
            }
            if (!this.money.oid) {
                this.$message.error('交易单号为必填项！');
                return false
            }
        },
        async addTransaction() {
            if (this.money) {
                this.verifyMoney()
                const res = await AddTransaction(this.money)
                if (res.data && res.data.code === 200) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.findByTime()
                    this.resetForm()
                } else {
                    this.$message.error(res.data.msg);
                }
            } else {
                this.$message.error('交易信息不存在！');
            }

        },
        udpTransaction() {
            this.$confirm('此操作将修改该交易记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                if (this.money && this.money.uuid) {
                    this.verifyMoney()
                    const res = await UdpTransaction(this.money)
                    if (res.data && res.data.code === 200) {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.findByTime()
                        this.resetForm()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                } else {
                    this.$message.error('交易信息不存在！');
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消修改'
                });
            });
        },
        delTransaction() {
            this.$confirm('此操作将删除该交易记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const uuids = []
                if (this.money && this.money.uuid) {
                    uuids.push(this.money.uuid)
                    const res = await DelTransaction(uuids)
                    if (res.data && res.data.code === 200) {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.findByTime()
                        this.resetForm()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                } else {
                    this.$message.error('交易信息不存在！');
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        resetForm() {
            this.money = {
                time: null,
                times: null,
                types: null,
                moneyType: null,
                num: 0.00,
                bank: null,
                oName: '',
                oid: '',
                remark: ''
            }
            this.dialogVisible = false
            this.chooseDate = ''
        }
    }
}

</script>

<style lang="scss" scoped>
.dashboard-container {
    width: 100%;
    /*height: 60%;*/
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
    margin: 0 auto;
}

::v-deep .fc-toolbar-chunk {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .fc-toolbar-title {
        margin: 0 30px;
    }

    .fc-button-primary {
        color: #333;
        background-color: #f4f4f5;
        border-color: #f4f4f5;
        margin: 0 10px;
        padding: 0.2em 0.4em;
    }

    .fc-button-primary:active,
    .fc-button-primary:focus {
        background-color: #f4f4f5;
        border-color: #f4f4f5;
        box-shadow: 0 0 0 transparent;
    }
}

::v-deep .fc-daygrid-day-frame {
    .fc-event-title {
        white-space: pre-wrap;
    }

    .fc-event-time {
        display: none;
    }
}

::v-deep .fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(199, 237, 204);
}

::v-deep .fc-scrollgrid {
    background-color: #f4f4f5;
}
</style>