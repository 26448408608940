import axios from 'axios'

const http = axios.create({
  baseURL: 'https://api.starliang.xyz:8086',
  // baseURL: 'http://127.0.0.1:8086',
  timeout: 30000
})

http.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers.Token = localStorage.getItem("Token")
  return config
}, function (error) {
  return Promise.reject(error)
})

http.interceptors.response.use(function (response) {
  switch (response.data.code) {
    case 201:
      localStorage.removeItem("Token")
      router.push('/login')
    case 202:
      localStorage.removeItem("Token")
      router.push('/login')
    case 203:
      localStorage.removeItem("Token")
      router.push('/login')
  }
  return response
}, function (error) {
  // 对响应错误做点什么
  if (error.response) {
    switch (error.response.status) {
      case 400:
        localStorage.removeItem("Token")
        router.push('/login')
      case 500:
        localStorage.removeItem("Token")
        router.push('/login')
    }
  }
  return Promise.reject(error)
})

// // 添加请求拦截器
// axios.interceptors.request.use(function (config) {
//   // 在发送请求之前做些什么
//   // 判断是否存在token,如果存在将每个页面header添加token
//   // if (store.state.token) {
//   //   config.headers.common['token'] = store.state.token
//   // }
//   // return config
//   console.log(config)
// }, function (error) {
//   router.push('/login')
//   return Promise.reject(error)
// })
// // 添加响应拦截器
// axios.interceptors.response.use(function (response) {
//   // 对响应数据做点什么
//   return response
// }, function (error) {
//   // 对响应错误做点什么
//   if (error.response) {
//     switch (error.response.status) {
//       case 401:
//         store.commit('del_token')
//         router.push('/login')
//     }
//   }
//   return Promise.reject(error)
// })


export default http
