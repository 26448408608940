<template>
    <div>
        <div class="block">
            <el-date-picker v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
            </el-date-picker>
            <el-select v-model="bank" clearable placeholder="请选择交易方式" style="margin-left: 1%;">
                <el-option v-for="item in banks" :key="item.uuid" :label="item.bankName" :value="item.uuid">
                </el-option>
            </el-select>
            <el-button icon="el-icon-search" style="margin-left: 1%;" @click="selectData()" circle></el-button>

            <el-button-group style="float: right;">
                <el-button type="primary" icon="el-icon-edit" @click="addMoneyData()"></el-button>
                <el-button type="danger" icon="el-icon-delete" @click="delMoneyData()"></el-button>
                <el-button type="success" icon="el-icon-upload"></el-button>
            </el-button-group>
        </div>
        <div>
            <el-table ref="multipleTable" :data="tableData" height="733" border style="width: 100%;margin-top: 1%;"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="times" label="交易时间" width="200">
                </el-table-column>
                <el-table-column prop="myBank.bankName" label="交易方式" width="150">
                </el-table-column>
                <el-table-column prop="num" label="交易金额(单位/元)" width="200">
                </el-table-column>
                <el-table-column prop="mt.pidName" label="交易类型" width="150">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.mt.pidName === '收入' ? 'primary' : 'success'" disable-transitions>{{
                scope.row.mt.pidName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="mt.typeName" label="交易类型" width="150">
                </el-table-column>
                <el-table-column prop="oName" label="交易对象" width="300">
                </el-table-column>
                <el-table-column prop="oid" label="交易单号" width="300">
                </el-table-column>
                <el-table-column prop="remark" label="备注">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="145">

                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block" style="margin-top: 1%;float: right;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                :page-sizes="[12, 24, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <div>
            <el-dialog :title="addMoney ? '新增' : '修改'" :visible.sync="dialogVisible" width="550px"
                :before-close="handleClose">
                <el-form :model="money" :ref="money" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="交易时间" required>
                        <el-col :span="17">
                            <el-date-picker v-model="money.time" type="datetime" placeholder="选择日期时间" align="right"
                                style="width: 100%;" :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="交易类型" required>
                        <el-form-item>
                            <el-cascader v-model="money.types" :options="types" :props="{ expandTrigger: 'hover' }"
                                style="width: 290px;">
                            </el-cascader>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="交易金额" required>
                        <el-form-item width="150px">
                            <el-input-number v-model="money.num" :precision="2" :step="0.01" style="width: 290px;"
                                controls-position="right"></el-input-number>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="交易方式" required>
                        <el-col :span="17">
                            <el-form-item>
                                <el-select v-model="money.bank" placeholder="选择方式" style="width: 290px;">
                                    <el-option v-for="item in banks" :key="item.uuid" :label="item.bankName"
                                        :value="item.uuid">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="交易对象" required>
                        <el-col :span="17">
                            <el-form-item>
                                <el-input placeholder="请输入对象名" v-model="money.oName" clearable>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="交易单号" required>
                        <el-col :span="17">
                            <el-form-item>
                                <el-input placeholder="请输入订单号" v-model="money.oid" clearable>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="交易备注" required>
                        <el-col :span="17">
                            <el-form-item>
                                <el-input type="textarea" placeholder="请输入备注" v-model="money.remark" maxlength="100"
                                    show-word-limit>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="resetForm()" style="float: left;">取 消</el-button>
                    <el-button v-if="addMoney" type="primary" @click="addTransaction()">添 加</el-button>
                    <el-button v-if="!addMoney" type="warning" @click="udpTransaction()">修 改</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { TransactionList, AddTransaction, DelTransaction, UdpTransaction, GetMyBankList, GetMoneyTypeList } from "@/api/Transaction"
import dayjs from 'dayjs'
export default {
    data() {
        return {
            tableData: [],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            time: [],
            firstTime: '',
            lastTime: '',
            banks: {},
            bank: '',
            pageSize: 12,
            pageNum: 1,
            total: 0,
            multipleSelection: [],
            dialogVisible: false,
            money: {
                time: '',
                times: '',
                bank: '',
                types: '',
                moneyType: '',
                oName: '',
                oid: '',
                num: 0.00,
                remark: ''
            },
            addMoney: true,
            types: []
        }
    },
    mounted() {
        this.defaultData()
        this.getMyBankList()
        this.getMoneyTypeList()
    },
    methods: {
        defaultData() {
            const time = new Date()
            const firstTime = new Date(time.getFullYear(), time.getMonth(), 1)
            const lastTime = new Date(time.getFullYear(), time.getMonth() + 1, 0)
            const firstDay = firstTime.getDate()
            const lastDay = lastTime.getDate()
            const month = String(time.getMonth() + 1).padStart(2, '0');
            const year = time.getFullYear()
            this.firstTime = year + '-' + month + '-' + firstDay + " 00:00:00"
            this.lastTime = year + '-' + month + '-' + lastDay + " 23:59:59"
            this.time.push(firstTime)
            this.time.push(lastTime)
            this.getData()
        },
        async getData() {
            const params = { startTime: this.firstTime, endTime: this.lastTime, bank: this.bank, pageSize: this.pageSize, pageNum: this.pageNum }
            const { data: res } = await TransactionList(params)
            this.total = res.data.total
            this.pageSize = res.data.pageSize
            this.pageNum = res.data.pageNum
            this.tableData = res.data.list
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.pageNum = 1
            this.getData()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.getData()
        },
        handleEdit(index, row) {
            this.money.uuid = row.uuid
            this.money.bank = row.bank
            this.money.types = row.moneyType
            this.money.num = row.num
            this.money.remark = row.remark
            this.money.moneyType = row.moneyType
            this.money.time = row.times
            this.money.times = row.times
            this.money.oName = row.oName
            this.money.oid = row.oid
            this.dialogVisible = true
            this.addMoney = false
        },
        handleDelete(index, row) {
            this.$confirm('此操作将删除该交易记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.delTransaction([row.uuid])
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        selectData() {
            const firstYear = this.time[0].getFullYear()
            const firstMonth = this.time[0].getMonth() + 1
            const firstDay = this.time[0].getDate()
            const lastYear = this.time[1].getFullYear()
            const lastMonth = this.time[1].getMonth() + 1
            const lastDay = this.time[1].getDate()
            this.firstTime = firstYear + '-' + firstMonth + '-' + firstDay + " 00:00:00"
            this.lastTime = lastYear + '-' + lastMonth + '-' + lastDay + " 23:59:59"
            this.getData()
        },
        async getMyBankList() {
            const userInfo = JSON.parse(localStorage.getItem('UserInfo'))
            const res = await GetMyBankList({ myUuid: userInfo.uuid })
            if (res.data && res.data.code === 200 && res.data.data) {
                this.banks = res.data.data
            }
        },
        async getMoneyTypeList() {
            const res = await GetMoneyTypeList()
            if (res.data && res.data.code === 200 && res.data.data) {
                res.data.data.map((item) => {
                    item.moneyTypeList.map((i) => {
                        i.value = i.uid
                        i.label = i.typeName
                    })
                    item.value = item.uid
                    item.label = item.typeName
                    item.children = item.moneyTypeList
                })
                this.types = res.data.data
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        addMoneyData() {
            this.dialogVisible = true
            this.addMoney = true
        },
        delMoneyData() {
            this.$confirm('此操作将删除该交易记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                console.log(this.multipleSelection)
                if (this.multipleSelection.length > 0) {
                    const uuids = this.multipleSelection.map(item => {
                        return item.uuid
                    })
                    this.delTransaction(uuids)
                } else {
                    this.$message.error('请至少选择一条交易记录！');
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.resetForm()
                    done();
                })
                .catch(_ => { });
        },
        verifyMoney() {
            if (!this.money.time) {
                this.$message.error('交易时间为必选项！');
                return false
            }
            this.money.times = dayjs(this.money.time).format('YYYY-MM-DD HH:mm:ss')
            if (!this.money.types) {
                this.$message.error('交易类型为必选项！');
                return false
            }
            if (this.money.types[1]) {
                this.money.moneyType = this.money.types[1]
            }
            if (this.money.num === 0) {
                this.$message.error('交易金额不能为0元！');
                return false
            }
            if (!this.money.bank) {
                this.$message.error('交易方式为必选项！');
                return false
            }
            if (!this.money.oName) {
                this.$message.error('交易对象为必填项！');
                return false
            }
            if (!this.money.oid) {
                this.$message.error('交易单号为必填项！');
                return false
            }
        },
        async addTransaction() {
            if (this.money) {
                this.verifyMoney()
                const res = await AddTransaction(this.money)
                if (res.data && res.data.code === 200) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.defaultData()
                    this.resetForm()
                } else {
                    this.$message.error(res.data.msg);
                }
            } else {
                this.$message.error('交易信息不存在！');
            }

        },
        udpTransaction() {
            this.$confirm('此操作将修改该交易记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                if (this.money && this.money.uuid) {
                    this.verifyMoney()
                    const res = await UdpTransaction(this.money)
                    if (res.data && res.data.code === 200) {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.defaultData()
                        this.resetForm()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                } else {
                    this.$message.error('交易信息不存在！');
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消修改'
                });
            });
        },
        async delTransaction(uuids) {
            const res = await DelTransaction(uuids)
            if (res.data && res.data.code === 200) {
                this.$message({
                    message: res.data.msg,
                    type: 'success'
                });
                this.defaultData()
                this.resetForm()
            } else {
                this.$message.error(res.data.msg);
            }
        },
        resetForm() {
            this.money = {
                time: null,
                times: null,
                types: null,
                moneyType: null,
                num: 0.00,
                bank: null,
                oName: '',
                oid: '',
                remark: ''
            }
            this.dialogVisible = false
        }
    }
}
</script>