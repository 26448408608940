import http from '@/utils/axios'

export const GetDateList = (params) => {
    return http.get('/File/GetDateList', { params: params })
}

export const GetImageList = (data) => {
    return http.post('/File/GetImageList', data)
}

export const UploadImage = (data) => {
    return http({
        url: '/File/UploadImage', 
        method: 'post',
        data,
        ContentType: 'multipart/form-data'
    })
}